import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */
/* @jsx mdx */
import DefaultLayout from "/Users/wcenatus/Documents/GitHub/cwa-site/src/templates/MdxLayout.js";
export const _frontmatter = {};
const layoutProps = {
  _frontmatter
};
const MDXLayout = DefaultLayout;
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">


    <p><a parentName="p" {...{
        "href": "http://cwamerchantservices.com/wp-content/uploads/2015/05/Chase_debit_EMV_hero_phixr.jpg"
      }}><span parentName="a" {...{
          "className": "gatsby-resp-image-wrapper",
          "style": {
            "position": "relative",
            "display": "block",
            "marginLeft": "auto",
            "marginRight": "auto",
            "maxWidth": "300px"
          }
        }}>{`
      `}<span parentName="span" {...{
            "className": "gatsby-resp-image-background-image",
            "style": {
              "paddingBottom": "70%",
              "position": "relative",
              "bottom": "0",
              "left": "0",
              "backgroundImage": "url('data:image/jpeg;base64,/9j/2wBDABALDA4MChAODQ4SERATGCgaGBYWGDEjJR0oOjM9PDkzODdASFxOQERXRTc4UG1RV19iZ2hnPk1xeXBkeFxlZ2P/2wBDARESEhgVGC8aGi9jQjhCY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2NjY2P/wgARCAAOABQDASIAAhEBAxEB/8QAFgABAQEAAAAAAAAAAAAAAAAAAQAD/8QAFgEBAQEAAAAAAAAAAAAAAAAABAAB/9oADAMBAAIQAxAAAAFSUEtLb//EABgQAAMBAQAAAAAAAAAAAAAAAAACEhMB/9oACAEBAAEFAqYrpbGbGbGZ/8QAFhEBAQEAAAAAAAAAAAAAAAAAABEB/9oACAEDAQE/AYmv/8QAFhEBAQEAAAAAAAAAAAAAAAAAAAER/9oACAECAQE/AWx//8QAGRABAAIDAAAAAAAAAAAAAAAAADEyARBB/9oACAEBAAY/ArLZS4nX/8QAHRAAAgEEAwAAAAAAAAAAAAAAAAERITFhkUFxsf/aAAgBAQABPyFNSqjsaaqHLMzYlc7GJscren//2gAMAwEAAgADAAAAEDwP/8QAFhEBAQEAAAAAAAAAAAAAAAAAABFh/9oACAEDAQE/EKbv/8QAFxEBAAMAAAAAAAAAAAAAAAAAAAERYf/aAAgBAgEBPxC4Yv/EAB4QAAICAgIDAAAAAAAAAAAAAAERACEx0UFRgZHh/9oACAEBAAE/EHA8cg3BbwKvz7h67y3CywVnqWhpHDajwIAVb+J//9k=')",
              "backgroundSize": "cover",
              "display": "block"
            }
          }}></span>{`
  `}<img parentName="span" {...{
            "className": "gatsby-resp-image-image",
            "alt": "Chase_debit_EMV_hero_phixr",
            "title": "Chase_debit_EMV_hero_phixr",
            "src": "/static/a51835f675b2a8eb53e550cab19e7ab1/8fc94/Chase_debit_EMV_hero_phixr-300x211.jpg",
            "srcSet": ["/static/a51835f675b2a8eb53e550cab19e7ab1/8675c/Chase_debit_EMV_hero_phixr-300x211.jpg 100w", "/static/a51835f675b2a8eb53e550cab19e7ab1/3cc80/Chase_debit_EMV_hero_phixr-300x211.jpg 200w", "/static/a51835f675b2a8eb53e550cab19e7ab1/8fc94/Chase_debit_EMV_hero_phixr-300x211.jpg 300w"],
            "sizes": "(max-width: 300px) 100vw, 300px",
            "style": {
              "width": "100%",
              "height": "100%",
              "margin": "0",
              "verticalAlign": "middle",
              "position": "absolute",
              "top": "0",
              "left": "0"
            },
            "loading": "lazy",
            "decoding": "async"
          }}></img>{`
    `}</span></a></p>
    <p>{`Banking giant JP Morgan Chase & Co. has begun issuing EMV debit cards, with plans to convert its entire debit card portfolio of 34 million cards to chip by the end of 2016, Chase announced Tuesday. Chase began testing chip-enabled debit card issuance in Arizona and Illinois two months ago, a Chase spokesman tells Digital Transactions News. Chase expects 70% of its debit cards to have chips by year’s end. The spokesman would not say how much the EMV debit conversion will cost Chase. One estimate is that a chip card may cost between $3 and $7 to issue. On the low end, the conversion thus might cost $102 million.`}</p>
    <p>{`Chase debit card holders with lost, stolen, damaged, or expired cards will receive the chip versions first, as will consumers opening new accounts. “Our mass reissuance will occur in the next 12 months,” the spokesman says. The plans also call for converting Chase’s Liquid prepaid cards to chip.`}</p>
    <p>{`EMV is meant to better secure point-of-sale transactions by migrating credit and debit cards from magnetic-stripe payments to chip ones. Issuers are expected to dramatically increase the number of chip-enabled credit and debit cards over the next several months in anticipation of the payment card networks’ Oct. 1 liability shifts that will place the onus for fraudulent transactions at the point of sale on the party, whether it’s the merchant or acquirer, least prepared for an EMV transaction.`}</p>
    <p>{`CWA - Merchant Services continues to work with our industry partners to provide merchants with affordable, easy-to-implement EMV solutions with the industry's best security capabilities. To ensure your equipment is EMV compliant, contact CWA Merchant Support today at (866) 210-4625 X1 or via email at `}<a parentName="p" {...{
        "href": "mailto:merchantsupport@cwams.com"
      }}>{`merchantsupport@cwams.com`}</a>{`.`}</p>
    <p>{`Want to read the rest of this story? Check out `}<a parentName="p" {...{
        "href": "http://digitaltransactions.net/news/story/Chase-Begins-Issuing-EMV-Debit-Cards_-Expects-To-Reach-70_-of-Cardholders-by-Year_s-End"
      }}>{`Digital Transactions`}</a>{`.`}</p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      